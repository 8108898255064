.home {
    // --bd-purple: #4c0bce;
    // --bd-violet: #712cf9;
    // --bd-accent: #ffe484;
    // --bd-violet-rgb: 112.520718,44.062154,249.437846;
    // --bd-accent-rgb: 255,228,132;
    // --bd-pink-rgb: 214,51,132;

    --bd-purple: #323d55;
    --bd-violet: #d3b865;
    --bd-accent: #ffe484;
    --bd-violet-rgb: 50,61,85;
    --bd-accent-rgb: 255,228,132;
    --bd-pink-rgb: 214,51,132;

    .bd-masthead {
        padding: 3rem 0;
        // background-image: linear-gradient(180deg, rgba(var(--bs-body-bg-rgb), 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%),radial-gradient(ellipse at top left, rgba(var(--bs-primary-rgb), 0.5), transparent 50%),radial-gradient(ellipse at top right, rgba(var(--bd-accent-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center right, rgba(var(--bd-violet-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center left, rgba(var(--bd-pink-rgb), 0.5), transparent 50%);
        background-color: #ffffff;
    }

    .btn-bd-primary {
        --bs-btn-font-weight: 600;
        --bs-btn-color: var(--bs-white);
        --bs-btn-bg: var(--bd-violet);
        --bs-btn-border-color: var(--bd-violet);
        --bs-btn-border-radius: .5rem;
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-hover-bg: #a49b47;
        --bs-btn-hover-border-color: #a49b47;
        --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
        --bs-btn-active-color: var(--bs-btn-hover-color);
        --bs-btn-active-bg: #a49b47;
        --bs-btn-active-border-color: #a49b47;
    }
}

.navbar {
    @extend .navbar-dark;

    --bd-violet-rgb: 50,61,85;
    
    padding: .75rem 0;
    background-color: transparent;
    background-image: linear-gradient(to bottom, rgba(var(--bd-violet-rgb), 1), rgba(var(--bd-violet-rgb), 0.95));
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15),inset 0 -1px 0 rgba(0,0,0,0.15);
}

.text-violet {
    color: #323d55;
}

.list {
    .card {
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.01);
        }
    }
}

.navbar-brand {
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: rotate(-2deg) scale(1.1);
    }
}

//additional
.mgb-60 {
    margin-bottom: 60px;
}
.mgb-20 {
    margin-bottom: 20px;
}
.mgb-30 {
    margin-bottom: 30px;
}
footer .navbar-nav {
    flex-direction: row;
}
footer .nav-link {
    padding-right: 10px;
    padding-left: 10px;
}   
nav.header-breadcrumb.my-2 {
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    --bs-bg-opacity: 1;
    padding: 10px 0;
    margin-top: 0 !important;
}
.navbar {
    box-shadow: none !important;
}
.navbar-brand {
    padding-bottom: 20px !important;
}
.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-white);
    background-color: #323d55 !important;
}
a {
    color: rgba(50,61,85,1);
}
.text-primary {
    --bs-text-opacity: 1;
    color: rgba(50,61,85,1) !important;
}

.sidebar-members {
    padding: 20px;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    --bs-bg-opacity: 1;
    text-align: left;
    font-style: italic;
}

.sidebar-members .message {
    margin-bottom: 0 !important;
}

.sidebar {
    padding: 20px;
    background-color: #d3b865 !important;
    --bs-bg-opacity: 1;
    text-align: left;
    font-style: italic;
    color: #ffffff;
}

.sidebar .message-by {
    font-style: italic;
    font-size: 13px;
    font-weight: 300;
}

.cls-svg-icon {
    fill: #d3b865 !important;
    stroke-width: 100 !important;
}

.blog-images {
    width: 700px;
}
.w-100 {
    width: 100%;
}
